/* Function */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(ScrollTrigger, SplitText);

/* --- Split the text, Client Title --- */
function setupSplits() {
	const targets = gsap.utils.toArray(".scroll-text");
	targets.forEach((target) => {
		let SplitClient = new SplitText(target, { type: "words,chars" });
		let chars = SplitClient.chars; //an array of all the divs that wrap each character
		gsap.from(chars, {
			duration: 2,
			opacity: 1,
			// color: "#D7D2CF",
			color: "#413F3E",
			ease: "none",
			stagger: 0.3,
			scrollTrigger: {
				trigger: target,
				start: "top 70%",
				end: "bottom center",
				scrub: 0.5,
			},
		});
	});
}

setupSplits();
