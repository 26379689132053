const partnerForm = document.querySelector(".partner-form-wrapper");

if (partnerForm) {
	const partnerFormSection = document.querySelector(".full-image-video");
	const formButton = partnerFormSection.querySelectorAll(".partner-button");
	const formContainer = partnerForm.querySelector(".partner-form-container");
	const exit = partnerForm.querySelectorAll(".backdrop, .form-exit");
	const body = document.querySelector("body");
	formButton.forEach((button) => {
		button.addEventListener("click", function (e) {
			partnerForm.classList.add("display");
			setTimeout(function () {
				partnerForm.classList.add("active");
				formContainer.classList.add("active");
				body.style.overflow = "hidden";
			}, 1);
		});
	});

	exit.forEach((element) => {
		element.addEventListener("click", function (e) {
			body.style.overflow = "visible";
			partnerForm.classList.remove("active");
			formContainer.classList.remove("active");
			setTimeout(function () {
				partnerForm.classList.remove("display");
			}, 300);
		});
	});
}

const contactForm = document.querySelector(".contact-form-wrapper");
console.log(contactForm);
if (contactForm) {
	const contactFormSection = document.querySelector(".contact-form");
	const formButton = contactFormSection.querySelectorAll(".partner-button");
	const formContainer = contactForm.querySelector(".contact-form-container");
	const exit = contactForm.querySelectorAll(".backdrop, .form-exit");
	const body = document.querySelector("body");
	formButton.forEach((button) => {
		button.addEventListener("click", function (e) {
			contactForm.classList.add("display");
			setTimeout(function () {
				contactForm.classList.add("active");
				formContainer.classList.add("active");
				body.style.overflow = "hidden";
			}, 1);
		});
	});

	exit.forEach((element) => {
		element.addEventListener("click", function (e) {
			body.style.overflow = "visible";
			contactForm.classList.remove("active");
			formContainer.classList.remove("active");
			setTimeout(function () {
				contactForm.classList.remove("display");
			}, 300);
		});
	});
}
