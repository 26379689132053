import "./_body-view-height";
import "./_body-view-width";
import "./_header-height";
import "./header";
import "./_search";
import "./_image-gallery-slider-configuration.js";
import "./_image-gallery-slider.js";
import "./_submenu.js";

let skipHeader = document.querySelector("a.visually-hidden.skip-to-main");
if (skipHeader != undefined) {
	skipHeader.addEventListener("focusin", function (e) {
		this.classList.add("focused");
	});
	skipHeader.addEventListener("focusout", function (e) {
		this.classList.remove("focused");
	});
}

let countryCode = sessionStorage.getItem("countryCode") || null;
if (!countryCode) {
	dbip.getVisitorInfo().then((info) => {
		countryCode = info?.countryCode?.toLowerCase();
		if (!countryCode) return;
		sessionStorage.setItem("countryCode", countryCode);

		if (window.renderBlocksBasedOnCountry) {
			window.renderBlocksBasedOnCountry(countryCode);
		}

		if (window.selectDealerElement) {
			window.selectDealerElement(countryCode);
		}
	});
}

function getPosts() {
	let data = new FormData();
	data.append("action", "get_posts");

	$.ajax({
		url: project_scripts.ajax_url,
		type: "POST",
		data: data,
		cache: false,
		processData: false,
		contentType: false,
		error: function (data) {
			console.error(data);
		},
		complete: function (data) {
			console.log(data.responseText);
		},
	});
}

import "./_three-col-features-slider.js";
import "./_three-col-features-slider-configuration.js";
import "./_hover-button.js";
import "./_three-col-feature.js";
import "./_products.js";
import "./_scroll-animation-features.js";
import "./_text-color-animation.js";
import "./_amina-s-sequence-animation.js";
import "./_block-technical-specifications.js";

import "./_archive-list.js";
import "./_amina-s-text-animation.js";

import "./_block-find-dealer-accordion.js";
import "./_block-hover-block.js";

import "./_contact-page.js";

import "./_contact-form.js";
import "./_accordion-with-text-and-image.js";
import "./_cookiebot.js";
import "./_lazy-video.js";
import "./_lazy-image.js";
import "./_product-submenu.js";
import "./_mavenoid.js";
import "./_block-find-dealer-country.js";
import "./_block-choose-dealer-country.js";
import "./_coutry-spesific-blocks.js";
import "./_order-blocks-by-country.js";
