import { intersectionObserve } from "./_intersection-observer";

//get all section buttons

const wrapper = document.querySelector(".technical-specifications .wrapper");

const sectionBtns = document.querySelectorAll(
	".technical-specifications .open-section"
);
const allHideElements = document.querySelectorAll(
	".technical-specifications .hide"
);
const firstHideElements = document.querySelectorAll(
	".technical-specifications .col-1 .hide"
);

const accordions = document.querySelectorAll(
	".technical-specifications .accordion"
);

const accordionWrapper = document.querySelectorAll(
	".technical-specifications .accordion .accordion-wrapper"
);

// function changeHeight() {
//   console.log(wrapper.querySelector('.col').offsetHeight);

//   wrapper.style.Height = wrapper.querySelector('.col').offsetHeight + 'px';
// }

if (accordions.length > 0) {
	if (window.innerWidth <= 900) {
		accordions.forEach((element) => {
			const child = element.firstElementChild;

			const show = child?.querySelector(".accordion-wrapper");

			// show.style.display = 'block';
			show?.classList.add("show");
		});
	} else {
		if (wrapper) {
			setTimeout(() => {
				wrapper.style.minHeight =
					document.querySelector(".technical-specifications .col-1")
						.offsetHeight + "px";
			}, 600);
		}

		const firstCol = document.querySelector(
			".technical-specifications .col-1 .accordion .accordion-wrapper"
		);
		const firstColButton = document.querySelector(
			".technical-specifications .col-1 .accordion button"
		);
		firstColButton.classList.add("glow");
		firstCol.classList.add("show");
	}
}

if (window.innerWidth >= 900) {
	firstHideElements.forEach((element) => {
		element.style.display = "block";
		element.classList.add("show");
	});
	//animate inn elements when scrolled to on large screens
	intersectionObserve(
		firstHideElements,
		0,
		0,
		(element, intersectionRatio) => {
			(function (el) {
				if (
					intersectionRatio > 0 &&
					!el.classList.contains("animate-up")
				) {
					el.classList.add("animate-up");
					setTimeout(() => {
						el.classList.remove("animate");
						// el.classList.add('show');
					}, 300);
				}
			})(element);
		}
	);
}

//for each button get single buttons and the section that it belongs to

sectionBtns.forEach((sectionBtn) => {
	const parent = sectionBtn.parentNode;
	const firstCol = parent.querySelector(".accordion .accordion-wrapper");
	const firstColButton = parent.querySelector(" .accordion button");
	const allSiblings = parent.querySelectorAll(".hide");

	//onclick/mouse over add hide to allHideElements, add or remove the "hide" class on allSiblings

	function showHiddenElements() {
		if (!sectionBtn.classList.contains("active")) {
			sectionBtns.forEach((button) => {
				if (button != sectionBtn) {
					button.classList.remove("active");
				}
			});

			// const wrapper = parent.parentNode;
			// wrapper.style.height = parent.offsetHeight + 'px';
			sectionBtn.classList.add("active");

			allHideElements.forEach((allHideElement) => {
				allSiblings.forEach((siblings) => {
					if (siblings != allHideElement) {
						allHideElement.classList.remove("show");

						setTimeout(() => {
							allHideElement.style.display = "none";
						}, 300);
					}
				});
			});

			accordionWrapper.forEach((element) => {
				element.classList.remove("show");
			});

			setTimeout(() => {
				allSiblings.forEach((siblings) => {
					siblings.style.display = "block";

					setTimeout(() => {
						siblings.classList.add("show");

						//show first element
						firstColButton.classList.add("glow");
						firstCol.classList.add("show");
					}, 300);
				});
			}, 300);

			setTimeout(() => {
				// changeHeight();
				console.log(parent.offsetHeight);
				wrapper.style.minHeight = parent.offsetHeight + "px";
			}, 1500);
		}
	}

	// sectionBtn.onmouseover = function () {
	//   showHiddenElements();
	// };
	sectionBtn.onclick = function () {
		showHiddenElements();
	};
});

//open/close accordion items

const buttonsColOne = document.querySelectorAll(
	".technical-specifications .col-1 .open"
);

if (buttonsColOne) {
	buttonsColOne.forEach((button) => {
		//accordion-wrapper
		const content = button.nextElementSibling;

		button.onclick = () => {
			const openAccordions = document.querySelectorAll(
				".technical-specifications .col-1 .accordion-item .show"
			);

			if (content.classList.contains("show")) {
				openAccordions.forEach((openAccordion) => {
					openAccordion.classList.remove("show");
					buttonsColOne.forEach((button) => {
						button.classList.remove("glow");
					});
				});
			} else {
				openAccordions.forEach((openAccordion) => {
					openAccordion.classList.remove("show");
					buttonsColOne.forEach((button) => {
						button.classList.remove("glow");
					});
				});
				// const parent = button.parentNode.parentNode.parentNode;

				// const wrapper = parent.parentNode;

				setTimeout(() => {
					content.classList.add("show");
					button.classList.add("glow");
				}, 100);

				// setTimeout(() => {
				//   console.log(parent.offsetHeight);
				//   wrapper.style.height = parent.offsetHeight + 'px';
				// }, 500);
			}
		};
	});
}

const buttonsColThree = document.querySelectorAll(
	".technical-specifications .col-3 .open"
);

if (buttonsColThree) {
	buttonsColThree.forEach((button) => {
		//accordion-wrapper
		const content = button.nextElementSibling;
		button.onclick = () => {
			const openAccordions = document.querySelectorAll(
				".technical-specifications .col-3 .accordion-item .show"
			);

			if (content.classList.contains("show")) {
				openAccordions.forEach((openAccordion) => {
					openAccordion.classList.remove("show");
					buttonsColThree.forEach((button) => {
						button.classList.remove("glow");
					});
				});
			} else {
				openAccordions.forEach((openAccordion) => {
					openAccordion.classList.remove("show");
					buttonsColThree.forEach((button) => {
						button.classList.remove("glow");
					});
				});

				setTimeout(() => {
					content.classList.add("show");
					button.classList.add("glow");
				}, 100);
			}
		};
	});
}
